<template>
  <div class="home">
    <h1>{{ storeName }}</h1>


    <div v-if="errors" class="alert alert-warning">{{ errors }}</div>

    <div class="card-grid mb-5">
      <div v-for="account in accounts" :key="account.id" class="card shadow mb-4">
        <div class="card-header">{{ account.name }}</div>
        <div class="card-body">
          <div class="lead">${{ parseFloat(account.balance).toFixed(2) }}</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>

import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const errors = ref('')
const eshopStore = computed(() => store.state.eshop.store)
const storeName = computed(() => eshopStore.value && eshopStore.value.name || '-')
const waveStoreId = computed(() => store.getters['eshop/config']('WAVE_STORE_ID'))
const accounts = computed(() => {
  const a = eshopStore.value.accounts
  if (!a) return []
  return a.filter(a => a.id === (eshopStore.value.config.WAVE_ANCHOR_ACCOUNT_ID || ''))
})

function getWaveData() {
  if (!waveStoreId.value) {
    errors.value = 'You need to set up a Wave Account and add ID in Settings'
    return
  }
  store.dispatch('eshop/getWaveAccounts', 0)
      .catch(e => errors.value = 'getWaveAccounts error: ' + e.message)
}

getWaveData()


</script>
