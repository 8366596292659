<template>
  <div v-if="input" class="card" >
    <div class="card-header d-flex align-items-center">
      <div class="me-3 text-muted small">{{ input.date }}</div>
      <div class="me-3">
        <span class="badge me-2 bg-success-subtle border border-success text-success">{{ notes.transactionType || '' }}</span>
        {{ input.description }}
      </div>
      <div class="ms-auto">
        <strong :class="{'text-success': input.anchor.direction === 'DEPOSIT' || input.anchor.direction === 'CREDIT', 'text-danger': input.anchor.direction === 'WITHDRAWAL'}">
          ${{ input.anchor.amount.toFixed(2) }}
        </strong>
      </div>
    </div>
    <div class="card-body p-2">
      <div class="small" :class="{'d-none': hide}" @click="toggleDetails">
        <small>
        <pre class="small">{{ {...input, notes} }}</pre>
        </small>
      </div>
      <div class="d-flex align-items-center">
        <button type="button" class="btn btn-link btn-sm" @click="toggleDetails">{{ hide ? 'Show' : 'Hide'}} details</button>
        <div class="ms-auto">
          <transition mode="out-in">
            <loading-spinner v-if="loading" />
            <div v-else-if="imported" class="text-success">Added</div>
            <button v-else type="button" class="btn btn-sm btn-light btn-outline-primary" @click="onAddInput(input)">Add To Waves</button>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { defineProps, ref, computed } from 'vue'
  import { useStore } from 'vuex'
  import { useAuth0 } from '@auth0/auth0-vue'
  import LoadingSpinner from "@/components/LoadingSpinner.vue";

  const hide = ref(true)
  const loading = ref(true)
  const imported = ref(false)
  const store = useStore()
  const auth0 = useAuth0()

  const props = defineProps({
    input: {
      type: Object,
      default: null
    }
  })

  const notes = computed(() => JSON.parse(props.input.notes || '{}'))

  if (props.input && props.input.externalId) {
    store.dispatch('eshop/checkTransaction', {auth0, key: props.input.externalId})
        .then(exists => {
          imported.value = !!exists
          loading.value = false
        })
  }

  function toggleDetails() {
    hide.value = !hide.value
  }

  function onAddInput(input) {
    loading.value = true
    store.dispatch('eshop/addWaveInput', input)
        .then(info => info.data)
        .then(data => data.moneyTransactionCreate)
        .then(data => {
          if (data.didSucceed) {
            return store.dispatch('eshop/mapTransaction', {auth0, transactionId: input.externalId})
          } else if (data.inputErrors) {
            const alreadyExists = data.inputErrors.filter(e => e.message === "A transaction with the same externalId already exists.")
            console.log(data.inputErrors, alreadyExists)
            if (alreadyExists.length > 0) {
              return store.dispatch('eshop/mapTransaction', {auth0, transactionId: input.externalId})
            }
          } else {
            return Promise.resolve(false)
          }
        })
        .then(exists => {
          imported.value = !!exists
          loading.value = false
        })
        .catch(e => {
          alert(e.message)
          loading.value = false
        })
  }
</script>
