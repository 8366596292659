<template>
  <div>
    <h1>Accounting</h1>

    <div v-if="waveStoreId">
      <div class="lead mb-5">
        <a :href="`https://next.waveapps.com/${waveStoreId}/transactions`" target="_blank">Wave Accounting</a>
      </div>

      <div v-if="errors" class="alert alert-warning">{{ errors }}</div>

      <h2>
        Recent eBay Transactions
      </h2>

      <recent-transactions />

    </div>
  </div>
</template>

<script setup>

import { computed } from 'vue'
import { useStore } from "vuex"

import RecentTransactions from "@/components/RecentTransactions.vue";
const store = useStore()
const waveStoreId = computed(() => store.getters['eshop/config']('WAVE_STORE_ID'))



</script>
