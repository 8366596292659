<template>
  <div>

    <div class="d-flex mb-3  align-content-center flex-wrap">
      <div
          v-for="type in ['SHIPPING_LABEL','REFUND','NON_SALE_CHARGE','SALE']"
          :key="type" type="button"
          class="badge badge-pill m-2 flex-fill"
          :class="{'bg-success-subtle text-success border border-success': excludeTypes.indexOf(type) === -1, 'bg-light border border-dark text-dark': excludeTypes.indexOf(type) !== -1}"
          @click="e=>toggleType(e,type)"
      >
        {{ type}}
      </div>
    </div>

    <transition mode="out-in">
      <loading-spinner v-if="loading" />
      <div v-else-if="eBayInputs.length === 0">
        <div class="text-info-emphasis mb-4">
          <small>No Matching Transactions</small>
        </div>
      </div>
      <div v-else>
        <transition-group name="group">
          <transaction-row v-for="input in eBayInputs" :key="input.externalId" :input="input" class="shadow shadow-sm mb-4" />
        </transition-group>
      </div>
    </transition>
  </div>
</template>

<script setup>

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import TransactionRow from "@/components/TransactionRow.vue";

import { ref, computed, defineProps } from 'vue'
import { useAuth0 } from "@auth0/auth0-vue"
import { useStore } from "vuex"

const auth0 = useAuth0()
const store = useStore()

const props = defineProps({
  transactionType: {
    type: String,
    required: false,
    default: 'ALL'
  }
})
const eBayInputs = computed(() => {
  if (!store.state.eshop.store.inputs) return []
  return store.state.eshop.store.inputs.slice().filter(t => excludeTypes.value.indexOf(JSON.parse(t.notes).transactionType) === -1)
})

const loading = ref(false)
const excludeTypes = ref(['SALE'])

const fdate = new Date()
const tdate = new Date()
fdate.setDate(fdate.getDate() - 2)
tdate.setDate(tdate.getDate() + 1)
const dates = `${ fdate.toISOString() }..${ tdate.toISOString() }`

function toggleType(e, type) {
  if (excludeTypes.value.indexOf(type) !== -1) {
    excludeTypes.value = excludeTypes.value.filter(t => t !== type)
  } else {
    excludeTypes.value.push(type)
  }
}

getEbayTransactions()

function getEbayTransactions() {
  if (eBayInputs.value.length) return
  loading.value = true
  store.dispatch('eshop/getEbayData', {
    auth0,
    type: props.transactionType || 'ALL',
    dates: dates //'2024-09-07T05:00:01.000Z..2024-09-09T05:00:01.000Z'
  })
      .then(() => loading.value = false)
      .catch(error => {
        loading.value = false
        console.log('getEbayData error', error)
      })
}
</script>
